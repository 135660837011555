



















































































































// import API from '@/plugins/axios';
import { imageData } from '@/utils/utilsData';
import API from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    imageData: { ...imageData },
    data: {
      name: '',
      description: '',
      maltese: false,
      image: 'misc',
      file_name: '',
      file_uuid: '',
      learning_outcomes: [
        {
          content: '',
        },
      ],
      minimum_evidence: [
        {
          content: '',
        },
      ],
    },
    dragging: false,
    isUploading: false,
  }),
  methods: {
    addNewEvidence() {
      this.data.minimum_evidence.push({
        content: '',
      });
    },
    addNewOutcome() {
      this.data.learning_outcomes.push({
        content: '',
      });
    },
    deleteOutcome(index: number) {
      this.data.learning_outcomes.splice(index, 1);
      this.data.learning_outcomes = [...this.data.learning_outcomes];
    },
    deleteEvidence(index: number) {
      this.data.minimum_evidence.splice(index, 1);
      this.data.minimum_evidence = [...this.data.minimum_evidence];
    },
    async saveUnit() {
      const unitData = { ...this.data };
      unitData.learning_outcomes = unitData.learning_outcomes.filter((item) => item.content);
      unitData.minimum_evidence = unitData.minimum_evidence.filter((item) => item.content);
      const { status, data } = await API.post('/administration/units/create/', unitData);
      if (status === 201) {
        await this.$router.push(`/admin/unit/${data.id}`);
      }
    },
    async onDrop(e: DragEvent) {
      if (e.dataTransfer?.files.length) {
        (this.$refs.fileInput as HTMLInputElement).files = e.dataTransfer.files;
        this.isUploading = true;
        const image = (this.$refs.fileInput as HTMLInputElement).files?.[0];
        if (image) {
          const form = new FormData();
          form.append('pdf_file', image);
          const { data } = await API.put('/upload/pdf-file/', form, {
            baseURL: process.env.VUE_APP_API_URL_FILES,
          });
          this.isUploading = false;
          this.data.file_name = data.original_name;
          this.data.file_uuid = data.name;
          console.log(data);
        }
      }
      this.dragging = false;
    },
    async upload() {
      const files = (this.$refs.fileInput as HTMLInputElement).files || ([] as unknown as FileList);
      this.isUploading = true;
      const image = files[0];
      if (image) {
        const form = new FormData();
        form.append('pdf_file', image);
        const { data } = await API.put('/upload/pdf-file/', form, {
          baseURL: process.env.VUE_APP_API_URL_FILES,
        });
        this.isUploading = false;
        this.data.file_name = data.original_name;
        this.data.file_uuid = data.name;
        console.log(data);
      }
    },
    async deletePdf() {
      await API.delete(`/pdf-file/${this.data.file_uuid}/`, {
        baseURL: process.env.VUE_APP_API_URL_FILES,
      });
      this.data.file_name = '';
      this.data.file_uuid = '';
    },
  },
  async mounted() {
    // const { data } = await API.get('administration/units/');
    // console.log(data);
  },
});
